<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center mb-5 mt-5">
              <h5 class="ps-2" style="border-left: 2px solid #0074ce">
                Edit Material
              </h5>
            </div>
            <form @submit.prevent="submit">
              <div v-if="courseMaterial.type === 'Video'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Title <span class="text-danger">*</span></label>
                    <input type="text" class="custom-form-control" v-model="courseMaterial.title" required />
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Description</label>
                    <textarea class="custom-form-control" v-model="courseMaterial.description"
                      style="height: 150px"></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Upload Thumbnail
                      <span class="text-danger">*</span></label>
                    <div class="position-relative">
                      <input type="file" ref="thumbnail" class="position-relative"
                        accept="image/png, image/jpg, image/jpeg" @change="selectThumbnail"
                        style="opacity: 0; z-index: 100" />
                      <button type="button" class="btn btn-primary primary-bg-color position-absolute" style="left: 0">
                        Browse...
                      </button>
                    </div>
                  </div>
                  <div class="image-group">
                    <div class="col-md-4 mt-3">
                      <img v-if="thumbnailPreview" :src="thumbnailPreview" class="img-thumbnail" style="width: 100%" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Upload Video <span class="text-danger">*</span></label>
                    <div class="position-relative">
                      <input type="file" ref="video" class="position-relative" accept="video/*"
                        @change="selectVideo($event)" style="opacity: 0; z-index: 100" />
                      <button type="button" class="btn btn-primary primary-bg-color position-absolute" style="left: 0">
                        Browse...
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-2 mt-2" v-if="fileUploadingPercentage > 0">
                      <progress :value="fileUploadingPercentage" max="100">
                        {{ fileUploadingPercentage }}%
                      </progress>
                      <p>{{ fileUploadingPercentage }}%</p>
                    </div>
                    <video :src="videoPreview" class="mt-3 w-100" id="video-preview" controls></video>
                  </div>
                </div>
              </div>
              <div v-if="courseMaterial.type === 'Quiz'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Select Quiz</label>
                    <select class="custom-form-control" v-model="courseMaterial.material_id">
                      <option value="" disabled>Select Quiz</option>
                      <option v-for="quiz in quizes" :key="quiz.id" :value="quiz.id">
                        {{ quiz.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="courseMaterial.type === 'Assignment'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Select Assignment</label>
                    <select class="custom-form-control" v-model="courseMaterial.material_id">
                      <option value="" disabled>Select Assignment</option>
                      <option v-for="quiz in assesments" :key="quiz.id" :value="quiz.id">
                        {{ quiz.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="courseMaterial.type === 'Document'">
                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <label class="primary-text-color">Title <span class="text-danger">*</span></label>
                    <input type="text" class="custom-form-control" v-model="courseMaterial.title" required />
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-md-12 mt-3">
                    <div class="form-group">
                      <div class="col-md-12 mt-3">
                        <label class="primary-text-color">Upload Document
                          <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <input type="file" ref="thumbnail" class="position-relative"
                            accept="application/pdf, application/msword" @change="selectDocument"
                            style="opacity: 0; z-index: 100" />
                          <button type="button" class="btn btn-primary primary-bg-color position-absolute"
                            style="left: 0">
                            Browse...
                          </button>
                        </div>
                      </div>
                      <div class="image-group">
                        <div class="col-md-4 mt-3">
                          <a v-if="document" :href="document" download>Perview Document</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="uploadProgress > 0">
                  <progress :value="uploadProgress" max="100">
                    {{ uploadProgress }}%
                  </progress>
                  <p>{{ uploadProgress }}%</p>
                </div>
                <div class="col-md-9">
                  <button type="submit" :disabled="fileUploadingPercentage > 0"
                    class="custom-btn primary-bg-color text-white btn-block w-100 mt-3">
                    <span v-if="fileUploadingPercentage > 0">Please wait, uploading file...</span>
                    <span v-else>Save</span>
                  </button>
                </div>
                <div class="col-md-3">
                  <button @click="cancel" type="button"
                    class="custom-btn-outline primary-text-color btn btn-block w-100 mt-3">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";
import axios from "@/helpers/axios";

export default {
  data() {
    return {
      loading: false,
      thumbnailPreview: null,
      videoPreview: null,
      materialId: null,
      courseMaterial: {
        title: null,
        description: null,
        course_level_id: null,
        thumbnail_file: null,
        video_file: null,
        material_id: "",
        quiz_id: "",
        assesment_id: "",
        document: "",
      },
      materialType: "",
      quizes: [],
      assesments: [],
      document: "",
      uploadProgress: 0,
      fileUploadingPercentage: 0,
      videoDuration: null,
    };
  },
  mounted() {
    this.materialId = this.$route.params.id;
    this.fetch(this.materialId);
    this.fetchAssesment();
    this.fetchQuiz();
  },
  methods: {
    goBack() {
      router.go(-1);
    },
    selectDocument() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.document = e.target.result;
      };
      reader.onloadend = () => {
        this.courseMaterial.document = reader.result;
      };
      reader.readAsDataURL(picture);
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("courseMaterial/getSingle", { id })
        .then((response) => {
          this.courseMaterial = response.data;
          if (this.courseMaterial.type == "Video") {
            this.courseMaterial.title = this.courseMaterial.material.title;
            this.courseMaterial.description =
              this.courseMaterial.material.description;
            this.thumbnailPreview =
              this.courseMaterial.material.thumbnail.image_url;
            this.$nextTick(() => {
              let video = document.getElementById("video-preview");
              if (video) {
                video.src = this.courseMaterial.material.video.image_url;
                this.videoPreview =
                  this.courseMaterial.material.video.image_url;
              }
            });
          } else if (this.courseMaterial.type == "Document") {
            this.courseMaterial.title = this.courseMaterial.material.title;
            this.document = this.courseMaterial.material.book_link.image_url;
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    selectThumbnail() {
      let picture = this.$refs.thumbnail.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnailPreview = e.target.result;
      };
      reader.onloadend = () => {
        this.courseMaterial.thumbnail_file = reader.result;
      };
      reader.readAsDataURL(picture);
    },
    selectVideo(event) {
      const file = event.target.files[0];
      this.videoPreview = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onloadend = async () => {
        this.$nextTick(() => {
          let video = document.getElementById("video-preview");
          video.src = this.videoPreview;
          video.onloadedmetadata = () => {
            this.courseMaterial.duration = parseFloat(
              (video.duration / 60).toFixed(2)
            );
          };
        });
      };
      reader.readAsDataURL(file);
      this.fileUploadingPercentage = 0.1;
      const chunkSize = 5 * 1024 * 1024;
      this.uploadFileInChunks(file, chunkSize);
    },
    async uploadFileInChunks(file, chunkSize) {
      const totalChunks = Math.ceil(file.size / chunkSize);
      const timestamp = Math.floor(Date.now() / 1000);
      const fileExtension = file.name.split('.').pop();
      const folder = 'course_material_videos';
      this.fileUploadingPercentage = "0.01";
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('index', i + 1);
        formData.append('totalChunks', totalChunks);
        formData.append('timestamp', timestamp);
        formData.append('fileExtension', fileExtension);
        formData.append('folder', folder);
        const response = await axios.post('/upload-video', formData);
        if (response.data.success) {
          this.fileUploadingPercentage = (((i + 1) / totalChunks) * 100).toFixed(2);
          this.courseMaterial.video = { path: response.data.file.path, name: file.type };
          if (response.data.videoStatus == true) {
            console.log("helllo");
            this.fileUploadingPercentage = 0;
           }
        } else {
          this.$toast.show(response.data.message, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
          return;
        }

      }
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.courseMaterial);
      const id = this.materialId;
      this.$store
        .dispatch("courseMaterial/update", {
          data,
          id,
          onUploadProgress: this.onUploadProgress,
        })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.thumbnail.value = null;
            this.$refs.video.value = null;
            this.uploadProgress = 0;
            this.fileUploadingPercentage = 0;
          }
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    fetchQuiz() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("quiz/getAll", { search, pagination })
        .then((response) => {
          this.quizes = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchAssesment() {
      this.loading = true;
      const search = "";
      const pagination = false;
      this.$store
        .dispatch("manualAssessment/getAll", { search, pagination })
        .then((response) => {
          this.assesments = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    cancel() {
      router.go(-1);
    },
  },
};
</script>
